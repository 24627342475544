import React from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
`;

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
// Here we create a component that will rotate everything we pass in over two seconds
const Rotate = styled.div`
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #555; /* grey */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
`;

const LoadingSpinner = () => (
  <SpinnerContainer>
    <Rotate />
  </SpinnerContainer>
);

export default LoadingSpinner;
